






















































































import ModelService from "rsui-core/src/core/model-service";
import RsSearcher from "rsui-core/src/system/package/search/rs-searcher";

const DlgEventListItmDlg = {
  props: ["closeDialog", "on", "id"],
  methods: {
    beforeSubmit(e, params) {
      return {
        params: { ...params, status: 1 },
        resolve: ({ response }) => {
          typeof this.on === "function" && this.on();
          this.closeDialog && this.closeDialog();
        },
      };
    },
  },
  render() {
    return (
      <rs-form
        name="ten-operation-log-setting"
        class="no-padding"
        no-border
        col="1"
        toolbar={{ forceAlign: "end", position: "bottom" }}>
        <rs-field name="name" required label="事件名称"></rs-field>
        <rs-field name="module" required label="应用模块"></rs-field>
        <rs-field
          required
          name="path"
          label="接口地址"
          widget={{ inputStyle: { height: "120px" }, type: "textarea" }}></rs-field>
        <rs-toolbar type="toolbar">
          <rs-button name="submit" auth={null} sort={999} label="确定" icon=""></rs-button>
        </rs-toolbar>
      </rs-form>
    );
  },
};
const DlgEventDetailDlg = {
  props: ["id", "closeDialog"],
  methods: {
    beforeRequest(e, params, eventId) {
      return {
        resolve: (e) => {
          console.info(e);
          e.params.request = JSON.stringify(JSON.parse(e.params.request), null, 2);
        },
      };
    },
  },
  render() {
    return (
      <rs-form
        name="ten-operation-log"
        class="no-padding"
        readonly
        no-border
        col="1"
        toolbar={{ forceAlign: "end", position: "bottom" }}>
        <rs-field name="setting" label="事件名称"></rs-field>
        <rs-field
          name="request"
          label="调用接口详情"
          widget={{ inputStyle: { height: "280px" }, type: "textarea" }}></rs-field>
        <rs-field name="tenant" label="租户名称"></rs-field>
        <rs-field name="crtTime" label="操作时间"></rs-field>
        <rs-field name="clientIp" label="IP地址"></rs-field>
        <rs-field name="responseStatus" label="操作结果"></rs-field>
        <rs-toolbar></rs-toolbar>
      </rs-form>
    );
  },
};
const service = new ModelService();
export default {
  components: { RsSearcher },
  data() {
    return {
      splitterModel: 350,
      searchtext: "",
      eventList: [],
    };
  },
  computed: {
    activeItm: function() {
      return this.eventList.find((i) => i.active) || {};
    },
    currentEventParams: function() {
      const { id } = this.activeItm;
      if (id === "all") {
        return [];
      }
      return [{ key: "setting.id", vals: [id] }];
    },
  },
  methods: {
    beforeQuery({ params }) {
      params[0].orderby = "crtTime desc";
      return {
        params,
      };
    },
    onTenOperationLogDetail(e, row) {
      console.info(e, row);
      this.$rs_dlg({
        parent: this,
        title: "事件详情",
        contentComponent: DlgEventDetailDlg,
        showFooter: false,
        cardStyle: "width:800px",
        contentComponentProps: {
          id: row.id,
        },
      });
    },
    onSearcherSearch(e) {
      console.info("search", e);
      this.getList({
        conditions: [
          {
            or: [
              { key: "name", vals: [e], op: "like" },
              { key: "path", vals: [e], op: "like" },
            ],
          },
        ],
      });
    },
    ontextchange() {},
    oninput() {},
    onrefresh() {},
    active(itm) {
      this.eventList.forEach((e) => {
        e.active = false;
      });
      itm.active = true;
    },
    deleteItm(itm) {
      //
      console.info(itm);
      this.$rs_confirm("确认删除？").then(() => {
        service.delete(itm.id, "ten-operation-log-setting").then(() => {
          this.onSearcherSearch("");
        });
      });
    },
    edit(itm) {
      //
      this.$rs_dlg({
        parent: this,
        title: "修改事件",
        contentComponent: DlgEventListItmDlg,
        showFooter: false,
        cardStyle: "width:500px",
        contentComponentProps: {
          id: itm.id,
          on: () => {
            this.onSearcherSearch("");
          },
        },
      });
    },
    add() {
      this.$rs_dlg({
        parent: this,
        title: "新建事件",
        contentComponent: DlgEventListItmDlg,
        showFooter: false,
        cardStyle: "width:500px",
        contentComponentProps: {
          on: () => {
            this.onSearcherSearch("");
          },
        },
      });
    },
    getList(params) {
      service.allListByModel({ ...params, orderby: "recTime desc" }, "ten-operation-log-setting").then((resp) => {
        console.info(resp);
        const lst = (resp?.list || []).map((i) => {
          const appname = `[${i.module?.id}] ${i.name}`;
          return {
            id: i.id,
            label: appname,
            title: `${appname} (${i.path})`,
            active: false,
          };
        });
        this.eventList = [{ label: "全部", title: "全部", active: true, id: "all" }, ...lst];
      });
    },
  },
  mounted() {
    this.getList();
  },
};

// VUE JSX HOT LOADER //
if (module.hot) require("/home/gitlab-runner/builds/8fef9614/0/portal/runsa-ops/node_modules/vue-jsx-hot-loader/src/api.js")({ Vue: require('vue'), ctx: eval('this'), module: module, hotId: "_vue_jsx_hot-288cbe6e/log_setting__list.vue" });